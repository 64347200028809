<template>
  <p>
    <strong>Как пройти тест?</strong> &ndash; На Главной странице по кнопке
    &laquo;Перейти к тесту&raquo; или выбрать доступный тест в меню слева,
    начать тестирование и ответить на все вопросы теста.
  </p>
  <p>
    <strong>Что делать, если не получается войти в личный кабинет?</strong>
    &ndash; восстановить пароль можно по электронной почте по адресу
    <a :href='url'>{{ url }}</a>.
  </p>
  <p>
    <strong>Где можно увидеть отчёт?</strong> &ndash; на Главной странице по
    кнопке &laquo;Получить отчет&raquo;.
  </p>
  <p>
    <strong>Где проходить курсы?</strong> &ndash; на Главной странице в разделе &laquo;Мои курсы&raquo; или выбрать
    вкладку нужного курса в меню слева (вкладка видна, если у вас есть активные доступы к курсам).
  </p>
  <p>
    <strong>Где посмотреть заказы?</strong> &ndash; для этого необходимо навести курсор на имя профиля в правом верхнем углу и выбрать
    в выпадающем списке пункт <a href="/orders">&laquo;Заказы&raquo;</a>.
  </p>
  <h3>Вкладки</h3>
  <p>
    <i class="fad fa-cube"></i>
    <strong>Главная</strong> &ndash; главная страница личного кабинета, здесь можно
    начать тестирование, посмотреть отчёт, сформированный по результатам теста, и доступные для
    прохождения курсы.
  </p>
  <p>
    <img src="@/assets/images/icons/report.svg" alt=""/>
    <strong>Тестирования</strong> &ndash; доступные тестирования ЭИ, после прохождения будет сформирован отчёт. Для
    прохождения тестирования необходимо приобрести отчёт (Стандартный, стартовый
    или полный).
  </p>
  <p>
    <img src="@/assets/images/icons/course.svg" alt=""/>
    <strong>Курсы</strong> &ndash; доступные для прохождения курсы.
  </p>
</template>

<script>
export default {
  name: "physicalFAQ",
  setup() {
    const url = process.env.VUE_APP_NEW_CABINET;

    return {
      url
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/faq";

.fad.fa-cube {
  margin-right: 5px;
}
</style>